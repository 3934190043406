
/**
* @author  dengqingbin
* @date  2022-02-17 15:42:08
* @version 1.0
* @description : 订单管理表格
*/
<template>
  <page-layout :fill-height="true" :tab-key="tabKey" :tab-list="tabList" title="支付账单管理" :tab-change="onTabChange">
    <!-- 抽佣 -->
    <div v-if="tabKey === 'commission' ">
      <a-form-model layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-model-item label="Invoice账单编号">
              <a-input v-model="commissionQueryParam.id" placeholder="" />
            </a-form-model-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-model-item label="店铺">
              <a-select v-model="commissionQueryParam.shopId" style="width: 200px">
                <a-select-option v-for="(item,index) in shopList" :key="index" :value="item.id">
                  {{ item.shopName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-model-item label="账单生成日期">
              <a-range-picker
                :show-time="{ format: 'HH:mm:ss' }"
                format="YYYY-MM-DD HH:mm:ss"
                :placeholder="['开始时间', '结束时间']"
                @change="onChange"
              />
            </a-form-model-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="commissionSearch()">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form-model>
      <a-table :row-key="(records) => records.id" :columns="commissionColumns" :data-source="commissionList" :pagination="commissionPagination" :loading="commissionLoading" @change="commissionChange">
        <span slot="status" slot-scope="text"> {{ text | statusFilter }} </span>
      </a-table>
    </div>
    <!-- 套餐 -->
    <div v-else>
      <a-form-model layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-model-item label="账单编号">
              <a-input v-model="comboQueryParam.orderNO" placeholder="" />
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-model-item label="付款时间">
              <a-range-picker
                :show-time="{ format: 'HH:mm:ss' }"
                format="YYYY-MM-DD HH:mm:ss"
                :placeholder="['开始时间', '结束时间']"
                @change="onChange"
              />
            </a-form-model-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="comboSearch()">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form-model>
      <a-table :row-key="(records) => records.id" :columns="comboColumns" :data-source="comboList" :pagination="comboPagination" :loading="comboLoading" @change="comboChange">
        <span slot="payType" slot-scope="text">
          {{ text | payTypeFilter }}
        </span>
      </a-table>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from './components/pageLayout.vue'
import { commissionColumns, comboColumns } from './config/columns'
import { getcommissionPage, getcomboPage } from '@/api/shopOrder'
import { shopList } from '@/api/register'

const statusMap = {
  1: {
    text: '待支付'
  },
  2: {
    text: '已支付'
  }
}

const payTypeMap = {
  1: {
    text: '微信支付'
  },
  2: {
    text: '支付宝支付'
  },
  3: {
    text: '银联支付'
  },
  5: {
    text: '周期付款'
  }
}

export default {
  components: {
    PageLayout
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    payTypeFilter(type) {
      return payTypeMap[type].text
    }
  },
  data() {
    return {
      shopList: [],
      commissionColumns,
      comboColumns,
      tabList: [
        {
          key: 'commission',
          tab: '抽佣账单'
        },
        {
          key: 'combo',
          tab: '套餐账单'
        }
      ],
      tabKey: 'commission',
      commissionQueryParam: {},
      commissionQuerySearch: {}, // 赋值查询-抽佣
      comboQueryParam: {},
      comboQuerySearch: {}, // 赋值查询-套餐
      commissionPagination: {},
      comboPagination: {},
      commissionLoading: false,
      comboLoading: false,
      commissionList: [],
      comboList: []
    }
  },

  created() {
    shopList().then(res => {
      if (res.code === 0) {
        this.shopList = res.data
      }
    })
  },

  mounted() {
    this.getcommissionPage()
  },
  methods: {
    onTabChange(key) {
      this.tabKey = key
      if (this.tabKey === 'combo' && this.comboList.length <= 0) {
        this.getcomboPage()
      }
    },
    // time
    onChange(value, dateString) {
      if (this.tabKey === 'commission') {
        this.commissionQueryParam.startTime = dateString[0]
        this.commissionQueryParam.endTime = dateString[1]
      } else {
        this.comboQueryParam.startTime = dateString[0]
        this.comboQueryParam.endTime = dateString[1]
      }

      console.log('Formatted Selected Time: ', dateString)
    },

    /** 佣金表格变更 */
    commissionChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.commissionPagination }
      pager.current = pagination.current
      this.commissionPagination = pager
      this.getcommissionPage({
        size: pagination.pageSize,
        current: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      })
    },

    /** 获取抽佣账单 */
    getcommissionPage(params = {}) {
      this.commissionLoading = true
      getcommissionPage({
        size: 10,
        ...params,
        ...this.commissionQuerySearch
      }).then(({ data }) => {
        const commissionPagination = { ...this.commissionPagination }
        commissionPagination.total = data.total
        this.commissionLoading = false
        this.commissionList = data.records
        this.commissionPagination = commissionPagination
      })
    },

    /** 抽佣账单查询 */
    commissionSearch() {
      this.commissionPagination.current = 1
      this.commissionQuerySearch = this.commissionQueryParam
      this.getcommissionPage()
    },

    /** 抽佣表格变更 */
    comboChange(pagination, filters, sorter) {
      const pager = { ...this.comboPagination }
      pager.current = pagination.current
      this.comboPagination = pager
      this.getcomboPage({
        size: pagination.pageSize,
        current: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      })
    },

    /** 获取套餐账单 */
    getcomboPage(params = {}) {
      this.comboLoading = true
      getcomboPage({
        size: 10,
        ...params,
        ...this.comboQuerySearch
      }).then(({ data }) => {
        const comboPagination = { ...this.comboPagination }
        comboPagination.total = data.total
        this.comboLoading = false
        this.comboList = data.records
        this.comboPagination = comboPagination
      })
    },

    /** 套餐账单查询 */
    comboSearch() {
      this.comboPagination.current = 1
      this.comboQuerySearch = this.comboQueryParam
      this.getcomboPage()
    }
  }
}
</script>

<style>

</style>
