import request from '@/utils/request'

// 抽佣账单
export function getcommissionPage(params) {
  return request({
    url: `/bill/commissionPage`,
    method: 'get',
    params
  })
}

// 套餐账单
export function getcomboPage(params) {
  return request({
    url: `/bill/comboPage`,
    method: 'get',
    params
  })
}
/** 轮询支付宝周期扣款状态  */
export function queryOrderAliPaySign(id) {
  return request({
    url: `/orderinfo/queryOrderAliPaySign`,
    method: 'get',
    params: { id }
  })
}
